<template>
    <div>
        <div class="container header-fixed">
            <div class="section">
                <div class="row justify-content-center">
                    <div class="col-md-10">
                        <div class="d-flex align-items-center pb-3 border-bottom mb-4">
                            <h1 class="m-0">Akun Saya</h1>
                        </div>

                        <div class="d-block mb-5">
                            <div class="row">
                                <div class="col-md-7">
                                    <div class="d-block mb-4 pb-3">
                                        <div class="d-block mb-3">Informasi Akun</div>
                                        <div class="card mb-2">
                                            <div class="d-flex align-items-center justify-content-between p-3">
                                                <div class="d-flex align-items-center">
                                                    <div class="icon-circle icon-circle-primary-light mr-3">
                                                        <span class="fe fe-user"></span>
                                                    </div>
                                                    <div>
                                                        <div class="d-block font-weight-bold">{{userdata.name}}</div>
                                                        <div class="text-sm">{{userdata.email}} <span v-if="userdata.phone">| {{userdata.phone}}</span></div>
                                                    </div>
                                                </div>
                                                <div>
                                                <router-link to="/signout" class="btn btn-white shadow-none"><span
                                                            class="fe fe-log-out mr-3"></span>Keluar</router-link>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-block text-sm text-muted pl-3 border-left">Cek status formulir
                                            anda melalui kotak masuk.</div>
                                    </div>
                                    <form v-on:submit.prevent="handleSubmit">
                                        <div class="d-block mb-5">
                                            <div class="d-block mb-3">Tambahkan Nomor Telepon</div>
                                            <input type="number" class="form-control mb-2"
                                                :class="{ 'is-invalid': submitted && $v.phone.$error }" v-model="phone"
                                                placeholder="Masukan Nomor Handphone">
                                            <div v-if="submitted && !$v.phone.required" class="invalid-feedback mb-3">Nomor Telepon tidak boleh kosong</div>
                                            <div v-if="submitted && !$v.phone.numeric" class="invalid-feedback mb-3">Format harus numeric</div>

                                            <div class="d-block text-sm text-muted pl-3 border-left">Silahkan isi untuk
                                                status informasi kelulusan & lainnya melalui WhatsApp. Kami tidak akan
                                                membagikan data apapun.</div>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <button type="submit" class="btn btn-primary-light px-4 mr-3">Simpan Perubahan</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    // @ is an alias to /src
    import { mapState } from 'vuex'
    import {required, numeric} from "vuelidate/lib/validators";

    export default {
        name: 'Formulir',
        components: {
            
        },
        data() {
            return {
                phone: "",
                submitted: false
            }
        },
        validations: {
            phone: {
                required,
                numeric
            }
        },
        mounted() {
            this.$store.dispatch('loadUserData')
        },
        computed: mapState([
            'userdata'
        ]),
        methods: {
            handleSubmit() {
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });
                    return;
                } else {
                    this.$http.post(this.$apiconfig + 'website/registrasi/update_profile', {
                        phone: this.phone
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                    icon: 'success',
                                    title: 'Berhasil!',
                                    text: response.data.message,
                                    confirmButtonText: 'OK',
                                    showCancelButton: false,
                                }).then((result) => {
                                    if (result.value) {
                                        this.$router.push('/formulir/unit');
                                    }
                                });
                        } else {
                            this.$swal({
                                icon: 'error',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                    })
                    .catch(function (error) {
                        // Swal.fire('Uh oh!', 'Please check your internet connection.', 'error')
                        console.debug("Login issue: " + error)
                    });
                }
            },
        }
    }
</script>